let instance = null;

class CodeVerification {
  constructor() {
    this.verificationCode = undefined;
  }

  static getInstance() {
    if (!instance) {
      instance = new CodeVerification();
    }

    return instance;
  }

  setVerificationCode = verificationCode => {
    this.verificationCode = verificationCode;
    return this;
  };

  getVerificationCode() {
    return this.verificationCode;
  }
}

export default CodeVerification;
