/* eslint-disable import/prefer-default-export */
import { Box } from '@vezeeta/web-components';
import styled from 'styled-components';

export const AppContainer = styled(Box)`
  padding-left: 1%;
  padding-right: 1%;
  ${props => props.theme.media.desktop`
    padding-left: 14%;
    padding-right: 14%;
  `};
`;
