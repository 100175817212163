let instance = null;

class ForgetPasswordMessage {
  constructor() {
    this.accountIdentifierInput = undefined;
    this.accountIdentifier = undefined;
    this.deliveryMethod = undefined;
  }

  static getInstance() {
    if (!instance) {
      instance = new ForgetPasswordMessage();
    }

    return instance;
  }

  setAccountIdentifierInput = accountIdentifierUserInput => {
    this.accountIdentifierUserInput = accountIdentifierUserInput;
    return this;
  };

  getAccountIdentifierInput() {
    return this.accountIdentifierUserInput;
  }

  setAccountIdentifier = accountIdentifier => {
    this.accountIdentifier = accountIdentifier;
    return this;
  };

  getAccountIdentifier() {
    return this.accountIdentifier;
  }

  setDeliveryMethod = deliveryMethod => {
    this.deliveryMethod = deliveryMethod;
    return this;
  };

  getDeliveryMethod() {
    return this.deliveryMethod;
  }
}

export default ForgetPasswordMessage;
