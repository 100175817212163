import React from 'react';
import PropTypes from 'prop-types';
import { Title, Button } from '@vezeeta/web-components';

import './NotFound.css';

function NotFound(props) {
  return (
    <div className="not-found-page center flex-col">
      <div className="flex-col">
        <Title className="number">404</Title>
        <Title className="not-found">The page you are trying to reach isn&apos;t found</Title>
      </div>

      <Button type="red" onClick={() => window.location.replace(props.redirectUrl)}>
        {props.buttonText}
      </Button>
    </div>
  );
}

NotFound.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default NotFound;
